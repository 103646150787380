<template>
  <div>

    <vuestic-widget>
      <form
        action=""
        autocomplete="off"
      >
        <div class="va-row">
          <div class="flex md6 xs12">
            <fieldset>
              <div class="form-group">
                <div class="input-group">
                  <input
                    required
                    v-model="modelo.nome"
                  />
                  <label
                    class="control-label"
                    for="tipo"
                  >Relato
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          <span class="mt-3">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="editarModeloDeRelato()"
              circle
            />
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="excluirModeloDeRelato()"
              circle
            />
          </span>
          <button
            class="ml-auto btn-style btn btn-primary btn-micro mt-2"
            @click.prevent="$refs.modalCadastrarTipo.open()"
          >
            Incluir tipo de relato
          </button>
        </div>
        <el-row
          type="flex"
          justify="end"
          class="mt-3"
        >

          <button
            class="btn btn-pale btn-micro mr-2"
            @click.prevent="$router.push({ name: 'lista-modelo-relato'})"
          >
            Voltar
          </button>
        </el-row>
      </form>

    </vuestic-widget>

    <full-relato
      :tipos="tipos"
      :grupos="grupos"
      :itens="itens"
      v-on:novoGrupo="cadastrarGrupo"
      v-on:novoItem="cadastrarItem"
      v-on:excluirGrupo="preparParaDeletar($event.id, 'grupo')"
      v-on:excluirItem="preparParaDeletar($event.id, 'item')"
      v-on:excluirTipo="preparParaDeletar($event.id, 'tipo-de-relato')"
      v-on:editarTipo="editarTipo"
      v-on:editarGrupo="editarGrupo"
    />
    <vuestic-modal
      ref="modalDeletar"
      v-on:ok="deletar(idByDeletar, nameWhatDelete)"
    >
      <div slot="title">Excluir</div>
      <div>Você deseja Excluir?</div>
    </vuestic-modal>
    <vuestic-modal
      :show.sync="show"
      ref="modalCadastrarTipo"
      v-on:ok="cadastarTipo()"
    >
      <div slot="title">Cadastrar </div>
      <div>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <p>Tipo do relato:</p>
              <input
                id="simple-input"
                v-model="tipo.nome"
                required
              >
              <label
                class="control-label"
                for="simple-input"
              ></label>
            </div>
          </div>
        </fieldset>
      </div>
    </vuestic-modal>
  </div>
</template>
<script>
import fullRelato from '@/components/my-components/ralatos/editar/relatoFull.vue';

export default {
  components: { fullRelato },
  name: 'editar-relatos',
  data() {
    return {
      show: false,
      nomeModelo: '',
      idModelo: null,
      idByDeletar: null,
      nameWhatDelete: '',
      tipos: [],
      itens: [],
      grupos: [],
      modelo: {
        nome: '',
      },
      grupo: {
        nome: '',
      },
      item: {
        nome: '',
        grupo: null,
      },
      tipo: {
        nome: '',
        modelo: null,
      },
    };
  },
  created() {
    this.idModelo = parseInt(this.$router.history.current.params.id, 10);
    this.getItens();
    this.getGrupos();
    this.getModelo(this.idModelo);
    this.grupo.tipo = this.tipo;
    this.grupo.modelo = this.idModelo;
    this.getTipos();
    this.tipo.modelo = this.idModelo;
  },

  methods: {
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    returnGrupos(id) {
      let resultado = [];
      if (this.idModelo && id) {
        resultado = this.grupos.filter(g => (g.modelo === this.idModelo)
          && (g.tipo === id));
      }
      return resultado;
    },
    getItens() {
      this.$axios.get('api/item/').then((res) => {
        this.itens = res.data;
      });
    },
    getTipos() {
      this.$axios.get(`/api/tipo-por-modelo/${this.idModelo}/`).then((res) => {
        this.tipos = res.data;
      });
    },
    getGrupos() {
      this.$axios.get('api/grupo/').then((res) => {
        this.grupos = (res.data);
      });
    },
    getModelo(id) {
      this.$axios.get(`api/modelo/${id}/`).then((res) => {
        this.nomeModelo = res.data.nome;
        this.modelo = res.data;
      });
    },
    cadastrarGrupo(novoGrupo) {
      const grupo = novoGrupo;
      this.$axios.post('api/grupo/', grupo).then(() => {
        this.message('success', 'Grupo Cadastrado!!!');
        this.getGrupos();
      });
    },
    cadastrarItem(item) {
      this.$axios.post('api/item/', item).then(() => {
        this.message('success', 'Item cadastrado!!!');
        this.getItens();
      });
    },
    cadastarTipo() {
      this.$axios.post('/api/tipo-de-relato/', this.tipo).then(() => {
        this.tipo.nome = '';
        this.message('success', 'Tipo cadastrado com sucesso');
        this.getTipos();
      }).catch(() => this.message('error', 'Erro ao tentar cadastrar'));
    },
    salvar() {
      this.$axios.put(`/api/modelo/${this.idModelo}/`, this.modelo).then(() => {
        // this.$router.push({ name: rota });
      });
      this.message('success', 'Alterações salvas com sucesso');
    },
    preparParaDeletar(id, name) {
      this.idByDeletar = id;
      this.nameWhatDelete = name;
      this.$refs.modalDeletar.open();
    },
    deletar(id, nome) {
      this.$axios.delete(`/api/${nome}/${id}/`).then(() => {
        let msg = '';
        if (nome === 'grupo') {
          msg = 'Grupo';
          this.getGrupos();
        } else if (nome === 'item') {
          msg = 'Item';
          this.getItens();
        } else {
          msg = 'Tipo de relato';
          this.getTipos();
        }
        this.message('success', `${msg} excluido!!!`);
      }).catch(() => this.message('error', 'Errou ao tentar excluir'));
    },
    editarTipo(tipo) {
      this.$axios.put(`/api/tipo-de-relato/${tipo.id}/`, tipo).then(() => {
        this.message('success', 'Tipo editado com sucesso');
        this.getTipos();
      }).catch(() => this.message('error', 'Erro ao tentar editar'));
    },
    editarModeloDeRelato() {
      this.$axios.put(`api/modelo/${this.modelo.id}/`, this.modelo).then((res) => {
        this.modelo = res.data;
        this.message('success', 'Relato editado com sucesso');
      }).catch(() => {
        this.message('error', 'Erro ao tentar editar');
        this.modelo.nome = this.nomeModelo;
      });
    },
    excluirModeloDeRelato() {
      this.$axios.delete(`api/modelo/${this.modelo.id}/`).then(() => {
        this.message('success', 'Relato excluido com sucesso');
        this.$router.push({ name: 'lista-modelo-relato' });
      }).catch(() => {
        this.message('error', 'Erro ao tentar excluir');
      });
    },
    editarGrupo(grupo) {
      this.$axios.put(`api/grupo/${grupo.id}/`, grupo).then(() => {
        this.message('success', 'Relato excluido com sucesso');
        const index = this.grupos.findIndex(g => g.id === grupo.id);
        this.grupos[index].nome = grupo.nome;
      }).catch(() => {
        this.message('error', 'Erro ao tentar excluir');
      });
    },
  },
};
</script>

<style>
.btn-style {
  height: 40px !important;
  border-radius: 0.7rem !important;
}
</style>
